import { CtaButton } from '@/components/general/CtaButton';
import { multipleChildren, rightFadeIn } from '@/constants/motionProps';
import { getCtaButtonVariant } from '@/utils/getCtaButtonVariant';
import { getTextColorClass } from '@/utils/getTextColorClass';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { useIsClient } from 'usehooks-ts';

import type { ContentProps } from './Content.types';

export const Content: FC<ContentProps> = ({
  action,
  defaultTextColor,
  content = '',
  subtitle = '',
  title = '',
}) => {
  const isClient = useIsClient();

  const defaultTextColorClass = getTextColorClass(defaultTextColor, 'text');

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={multipleChildren}
      viewport={{ once: true }}
      className={clsx([defaultTextColorClass])}
    >
      {!!(isClient && subtitle) && (
        <motion.h3
          className="mb-12 text-text-2-mob uppercase lg:text-text-2"
          variants={rightFadeIn}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}

      {!!(isClient && title) && (
        <motion.h2
          className="mb-12 text-header-2-mob font-medium uppercase lg:mb-32 lg:text-header-2"
          variants={rightFadeIn}
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}

      {!!(isClient && content) && (
        <motion.div
          className=" mb-20 text-text-2-mob lg:mb-44 lg:text-text-2"
          variants={rightFadeIn}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}

      {!!(isClient && action) && (
        <motion.div variants={rightFadeIn}>
          <CtaButton
            variant={getCtaButtonVariant(action, 'secondary')}
            type={action.type}
            action={action.type === 'action' ? action.action : undefined}
            url={
              action.type !== 'action' && action.type !== 'none'
                ? action.href
                : undefined
            }
          >
            {action.label}
          </CtaButton>
        </motion.div>
      )}
    </motion.div>
  );
};
