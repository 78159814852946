import { FmMedia } from '@/components/general/FmMedia';
import { multipleChildren, verticalFadeIn } from '@/constants/motionProps';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import type { FC } from 'react';

import type { ImagesProps } from './Images.types';

export const Images: FC<ImagesProps> = ({
  images = [],
  media = [],
  reverse,
}) => {
  const [image1, image2] = images;
  const [media1, media2] = media;

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      variants={multipleChildren}
      viewport={{ once: true }}
      className={clsx([
        'relative',
        'flex',
        { 'lg:flex-row-reverse': reverse },
        'mx-auto',
        'mb-100',
        'max-w-[440px]',
        'md:mb-50',
        'md:max-w-[660px]',
        'lg:max-w-[780px]',
      ])}
    >
      {!!(image1 || media1) && (
        <FmMedia
          media={image1 ? { type: 'image', value: image1 } : media1}
          variants={verticalFadeIn}
          videoProps={{
            videoProps: {
              className: 'object-cover',
            },
          }}
          className={clsx([
            'aspect-[3/4]',
            'max-w-[85%]',
            'shadow-xl',
            'md:max-w-[380px]',
            'lg:max-w-[500px]',
          ])}
        />
      )}

      {!!(image2 || media2) && (
        <FmMedia
          media={image2 ? { type: 'image', value: image2 } : media2}
          variants={verticalFadeIn}
          videoProps={{
            videoProps: {
              className: 'object-cover',
            },
          }}
          className={clsx([
            'absolute',
            'right-0',
            { 'lg:left-0': reverse },
            { 'lg:right-auto': reverse },
            'top-1/2',
            'max-w-[65%]',
            'shadow-xl',
            'md:top-1/4',
            'md:max-w-[300px]',
            'lg:top-1/3',
            'lg:max-w-[380px]',
          ])}
        />
      )}
    </motion.div>
  );
};
